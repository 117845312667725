
import { Navigation } from "@/mixins/navigation";
import { FormValidations } from "@/mixins/form-validations";
import Component, { mixins } from "vue-class-component";
import { StyleCheck } from "@/mixins/style-check";

@Component({})
export default class RegisteredPayment extends mixins(
  Navigation,
  FormValidations,
  StyleCheck
) {
  private get image(): string {
    let logo = this.$store.getters["system/getLogo"];
    if (logo) {
      return logo.url;
    } else {
      return "";
    }
  }
}
